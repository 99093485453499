
import { defineComponent, onMounted, ref } from "vue";
import cardArticle from "@/components/card-article/card-article.vue"
import { getArticle } from "@/api"
import router from "@/router"

export default defineComponent({
  components: {
    cardArticle
  },
  setup() {
    const smart = ref(null);
    const ranking = ref(null);
    const loading = ref(false);
    // 获取文章参数
    const articleParams = {
      label: "技术",
      pagenum: 1,
      pagesize: 7
    }
    // 分页参数
    const pagination = ref({
      current: 1,
      total: 10,
      pagesize: articleParams.pagesize
    })

    // 路由跳转
    const goArticle = (id: string) => {
      router.push(`/article?id=${id}`)
    }
    // 分页
    const changePage = (page: number) => {
      articleParams.pagenum = page
      loading.value = true;
      getArticle(articleParams).then((res: any) => {
        smart.value = res.data.data.articles
        loading.value = false;
        window.scrollTo(0, 0);
      })
    }
    onMounted(() => {
      loading.value = true;
      getArticle(articleParams).then((res: any) => {
        smart.value = res.data.data.articles
        pagination.value.total = res.data.data.total
        loading.value = false;
      })
      getArticle({pagenum: 1, pagesize: 5, label: "技术", order: "browse_num"}).then((res: any) => {
        ranking.value = res.data.data.articles
      })
    })
    return {
      smart,
      ranking,
      loading,
      articleParams,
      goArticle,
      pagination,
      changePage
    }
  }

})
