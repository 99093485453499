<template>
  <a target="_blank" :href="`/article?id=${article.id}`" id="card-article">
    <div class="tag">
      <img src="@/assets/img/resources.png" alt="">
      <span>{{ article.label }}</span>
    </div>
    <div class="title">
      <span>{{ article.title }}</span>
    </div>
    <div class="article">
      <div class="img">
        <img :src="article.cover" alt="">
      </div>
      <div class="text-box">
        <div class="info">
          {{ article.info }}
        </div>
        <div class="more">
          <div class="label">
            <span class="time">{{ article.time }}</span>
            <span class="time">作者 / {{ article.author }}</span>
            <span class="time">来源 / {{ article.source }}</span>
            <!-- <router-link to="/">官方主题</router-link>
            <router-link to="/">企业站</router-link>
            <router-link to="/">Chappie</router-link> -->
          </div>
        </div>
      </div>
    </div>
  </a>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    article: Object
  },
  setup() {
    
    return {
      
    }
  }
})
</script>

<style lang="scss">
  #card-article {
    cursor: pointer;

    .title {
      font-size: 15px;
      line-height: 24px;
      margin: 15px 0px;
      color: #454545;
      font-weight: 600;
      cursor: pointer;
    }

    .tag {
      cursor: pointer;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background: #f6f6f6;
      border-radius: 14px;
      height: 28px;
      font-size: 12px;
      color: #2254f4;
      position: relative;
      line-height: 28px;
      margin-bottom: 5px;
      overflow: hidden;
      width: 65px;
      padding-right: 10px;

      img {
        width: 25px;
        padding-left: 6px;
      }
    }

    .article {
      display: flex;
      justify-content: space-between;

      .img {
        margin-right: 15px;
        cursor: pointer;

        img {
          width: 140px;
          height: 90px;
        }
      }

      .text-box {
        width: 80%;
        font-size: 13px;
        color: #999;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .info {
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .more {
          margin-bottom: 15px;

          .label {
            display: inline-block;

            .time {
              // color: #666;
              display: inline-block;
              user-select: none;
            }

            span {
              margin-right: 20px;
              // padding: 4px 10px;
              // color: #999;
              border-radius: 4px;
              // &:hover {
              //   background: #2254f4;
              //   color: #fff;
              // }
            }
          }
        }
      }
    }
  }

    // 移动端
  @media screen and (max-width: 800px) {
    #card-article {

      .article {
        display: block;

        .img {
          margin-right: 15px;
          cursor: pointer;

          img {
            width: 100%;
            height: 150px;
            object-fit: cover;
          }
        }

        .text-box {

          .info {
            margin-top: 15px;
          }

          .more {
            margin: 15px 0;   

            .label {
              display: flex;
              justify-content: space-between;

              span {
                margin-right: 0px;
              } 
            }
          }
        }
      }
    }
  }
</style>