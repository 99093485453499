<template>
  <a-spin :spinning="loading" tip="加载中" :delay="500">
    <div id="smart" class="center">
      <div class="left">
        <div class="text-wrapper">
          <p>木小天</p>
          <h1># 技术</h1>
          <p>已更新<span>{{ pagination.total }}</span>条文章</p>
        </div>
      </div>
      <div class="middle">
        <div class="article">
          <div class="title-wrapper">
            <div class="title">技术分享</div>
          </div>
          <ul>
            <li v-for="item in smart" :key="item.id">
              <card-article :article="item"></card-article>
            </li>
          </ul>
          <div style="text-align: center;">
            <a-pagination 
              v-model:current="pagination.current" 
              :total="pagination.total" 
              v-model:pageSize="pagination.pagesize"
              show-less-items 
              @change="changePage"
            />
          </div>
        </div>
      </div>
      <div class="right">
        <div class="love">
          <div class="title-wrapper">
            <div class="title">精品推荐</div>
          </div>
          <ul>
            <li @click="goArticle(item.id)" v-for="(item, index) in ranking" :key="item.id">
              <img v-if="index == 0" :src="item.cover" alt="">
              <div class="title">{{ item.title }}</div>
              <div class="time">{{ item.time }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import cardArticle from "@/components/card-article/card-article.vue"
import { getArticle } from "@/api"
import router from "@/router"

export default defineComponent({
  components: {
    cardArticle
  },
  setup() {
    const smart = ref(null);
    const ranking = ref(null);
    const loading = ref(false);
    // 获取文章参数
    const articleParams = {
      label: "技术",
      pagenum: 1,
      pagesize: 7
    }
    // 分页参数
    const pagination = ref({
      current: 1,
      total: 10,
      pagesize: articleParams.pagesize
    })

    // 路由跳转
    const goArticle = (id: string) => {
      router.push(`/article?id=${id}`)
    }
    // 分页
    const changePage = (page: number) => {
      articleParams.pagenum = page
      loading.value = true;
      getArticle(articleParams).then((res: any) => {
        smart.value = res.data.data.articles
        loading.value = false;
        window.scrollTo(0, 0);
      })
    }
    onMounted(() => {
      loading.value = true;
      getArticle(articleParams).then((res: any) => {
        smart.value = res.data.data.articles
        pagination.value.total = res.data.data.total
        loading.value = false;
      })
      getArticle({pagenum: 1, pagesize: 5, label: "技术", order: "browse_num"}).then((res: any) => {
        ranking.value = res.data.data.articles
      })
    })
    return {
      smart,
      ranking,
      loading,
      articleParams,
      goArticle,
      pagination,
      changePage
    }
  }

})
</script>

<style lang="scss" scoped>
  #smart {
    display: flex;
    justify-content: space-between;

    .left {
      width: 13%;

      .text-wrapper {

        p {
          font-size: 12px;
          color: #999;

          span {
            color: #2254f4;
            margin: 0 5px;
            font-weight: 600;
          }
        }

        h1 {
          font-size: 32px;
          color: #333;
          line-height: 36px;
          margin-bottom: 10px;
          font-weight: 600;
        }
      }
    }

    .middle {
      width: 60%;

      .article {
        
        border-radius: 6px;
      }

      .title-wrapper {
        padding: 20px;
        background: white;
      }

      ul {
        margin-top: 20px;

        li {
          padding: 20px;
          background: white;
          border-radius: 6px;
          margin-bottom: 20px;
          border: 1px solid transparent;

          &:hover {
            border: 1px solid rgba(34, 84, 244,.5);
          }
        }
      }
    }

    .right {
      width: 23%;

      .love {
        background: white;
        padding: 20px;
        border-radius: 6px;
        ul {

          li {
            padding: 15px 0;
            border-bottom: 1px solid #eee;
            cursor: pointer;

            &:hover {
              
              .title {
                color: #2254f4;
              }
            }

            img {
              display: block;
              width: 240px;
            }

            .title {
              color: #454545;
              margin: 10px 0px;
              font-size: 14px;
              font-weight: 600;
            }

            .time {
              font-size: 12px;
              color: #999;
            }
          }
        }
      }
    }
  }

  // 移动端
  @media screen and (max-width: 800px) {
    #smart {

      .left {
        display: none;
      }

      .middle {
        width: 100%;
      }

      .right {
        display: none;
      }
    }
  }
</style>