
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    article: Object
  },
  setup() {
    
    return {
      
    }
  }
})
